import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.up("md")]: {
    image: { maxWidth: "100%" },
    imageContainer: {
      backgroundColor: (props) => props.color,
      padding: "32px",
    },
    caption: {
      color: "#a9a9a9",
    },
    largePull: {
      left: "4%",
      marginTop: theme.spacing(5),
      maxWidth: "250px",
      position: "absolute",
    },
    captionGrid: {
      marginTop: theme.spacing(1),
    },
    link: {
      borderBottom: (props) => `3px solid ${props.color}`,
      "&:hover": {
        cursor: "pointer",
      },
    },
    linkGrid: {
      marginTop: theme.spacing(1),
    },
  },
  [theme.breakpoints.down("sm")]: {
    image: { maxWidth: "100%" },
    imageContainer: {
      backgroundColor: (props) => props.color,
      padding: "32px",
    },
    largePull: {
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
    caption: {
      color: "#b1b1b1",
    },
    captionGrid: {
      marginTop: theme.spacing(1),
    },
    link: {
      borderBottom: (props) => `3px solid ${props.color}`,
      "&:hover": {
        cursor: "pointer",
      },
    },
    linkGrid: {
      marginTop: theme.spacing(1),
    },
  },
}));

const PortfolioPiece = ({ caption, title, link, imageSrc, ...props }) => {
  const classes = useStyles(props);
  const handleClick = () => {
    window.open(link);
  };
  return (
    <Grid container justify={"center"}>
      <Grid className={classes.imageContainer} container justify={"center"}>
        <Grid item sm={9}>
          <img className={classes.image} alt="portfolio" src={imageSrc} />
        </Grid>
      </Grid>
      <Grid className={classes.largePull} container direction={"column"}>
        <Grid item md={3} sm={12}>
          <Typography variant={"body2"}>{title}</Typography>
        </Grid>
        <Grid className={classes.captionGrid} item>
          <Typography variant={"caption"} className={classes.caption}>
            {caption}
          </Typography>
        </Grid>
        {link ? (
          <Grid item className={classes.linkGrid}>
            <Typography onClick={handleClick} className={classes.link} variant={"caption"}>
              View Website
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default PortfolioPiece;
