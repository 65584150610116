import React from "react";
import Grid from "@material-ui/core/Grid";
import { IphoneIcon } from "../../components";
import { Typography } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  iconGroup: {
    marginTop: theme.spacing(3),
  },
}));

const Contact = () => {
  const classes = useStyles();
  const iconSize = 56;
  return (
    <Grid className={classes.container} container justify={"center"} alignItems={"center"} direction={"column"}>
      <Grid item>
        <Typography align="center" variant={"h3"}>
          Reach Out 📱
        </Typography>
      </Grid>
      <Grid className={classes.iconGroup} container alignItems={"center"} justify="space-around" direction={"row"}>
        <Grid item>
          <IphoneIcon
            link={"mailto:donkersjack@gmail.com?subject=Let's%20work%20together!"}
            size={iconSize}
            src={"./gmailLogo.png"}
          />
        </Grid>
        <Grid item>
          <IphoneIcon link={"https://github.com/Jaqito"} size={iconSize} src={"./githubLogo.png"} />
        </Grid>
        <Grid item>
          <IphoneIcon
            link={"https://www.linkedin.com/in/jack-donkers-b98aa9103/"}
            size={iconSize}
            src={"./linkedInLogo.png"}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Contact;
