import { Grid, useTheme } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useState } from "react";
import { Switch } from "../../components";
import { Portfolio, Skills } from "../index";

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.up("md")]: {
    switch: {
      marginLeft: theme.spacing(12),
      marginTop: theme.spacing(10),
    },
    container: {
      marginTop: theme.spacing(11),
    },
  },
  [theme.breakpoints.down("sm")]: {
    portfolioPiece: {
      marginTop: theme.spacing(6),
    },
    container: {
      marginTop: theme.spacing(9),
    },
    switch: {
      marginTop: theme.spacing(6),
    },
  },
}));

const PortfolioAndSkills = () => {
  const classes = useStyles();
  const [switchValue, setSwitchValue] = useState("portfolio");

  const handleSwitch = (switchValue) => {
    setSwitchValue(switchValue);
  };
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid justify={mobile ? "center" : "flex-start"} alignItems={mobile ? "center" : "stretch"} container>
      <Grid item className={classes.switch}>
        <Switch handleSwitch={handleSwitch} />
      </Grid>

      <Portfolio hidden={switchValue !== "portfolio"} />
      <Skills hidden={switchValue !== "services"} />
    </Grid>
  );
};

export default PortfolioAndSkills;
