import { LandingPage, PortfolioAndSkills, Contact, Reviews } from "./containers";
import { ThemeProvider } from "@material-ui/core";
import React from "react";
import theme from "./theme";


function App() {
  return (
    <ThemeProvider theme={theme}>
      <LandingPage />
      <PortfolioAndSkills />
      <Reviews />
      <Contact />
    </ThemeProvider>
  );
}

export default App;
