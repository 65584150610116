import React from "react";
import { IphoneIconWithText } from "../index";
import Grid from "@material-ui/core/Grid";
import { Typography, useTheme } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  skillsSectionHeader: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(16),
  },
  skillsLogoAndText: {
    marginTop: theme.spacing(3),
  },
}));

const SkillsSection = ({ skillsAndIconsSrcObjects, skillsSectionHeader }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid container direction={"column"}>
      <Grid item className={classes.skillsSectionHeader}>
        <Typography align={mobile ? "center" : "inherit"} variant={"h3"}>
          {skillsSectionHeader}
        </Typography>
      </Grid>
      {skillsAndIconsSrcObjects.map((skillsAndIconsSrcObject, i) => {
        return (
          <Grid key={i} item className={classes.skillsLogoAndText}>
            <IphoneIconWithText size={48} text={skillsAndIconsSrcObject.text} src={skillsAndIconsSrcObject.src} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default SkillsSection;
