import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { IphoneIcon } from "../index";

const useStyles = makeStyles((theme) => ({
  root: {},
  [theme.breakpoints.up("md")]: {
    heading: {
      marginBottom: theme.spacing(2),
    },
    expandIcon: {
      marginRight: theme.spacing(1),
    },
  },
  [theme.breakpoints.down("sm")]: {
    heading: {
      marginBottom: theme.spacing(2),
    },
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
}));

const AccordionItem = ({ summary, logoSrc, smallBlackLogoSrcs, width, mobile }) => {
  const classes = useStyles();

  return (
    <Accordion
      classes={{
        root: classes.MuiAccordionroot,
      }}
      elevation={0}
    >
      <AccordionSummary
        classes={{
          expandIcon: classes.expandIcon,
        }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Grid container direction={"row"}>
          <Grid item xs={6}>
            <Grid container direction={"column"}>
              <Grid item className={classes.heading}>
                <Typography variant="subtitle1">Client</Typography>
              </Grid>
              <Grid item>
                <img alt={"Logo"} width={width} src={logoSrc} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container direction={"column"}>
              <Grid item className={classes.heading}>
                <Typography variant="subtitle1">Services Provided</Typography>
              </Grid>
              <Grid item>
                <Grid container spacing={1}>
                  {smallBlackLogoSrcs.map((logo, i) => {
                    return (
                      <Grid key={i + logo} item>
                        <IphoneIcon src={logo} radius={mobile ? "6px" : "10px"} size={mobile ? 20 : 28} />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant={"caption"}>{summary}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionItem;
