import React from "react";
import { IphoneIcon } from "../index";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
  typography: { width: "280px" },
}));

const IphoneIconWithText = ({ src, text, size }) => {
  const classes = useStyles();
  return (
    <Grid container alignItems={"center"} direction={"row"} spacing={4}>
      <Grid item>
        <IphoneIcon src={src} size={size} />
      </Grid>
      <Grid className={classes.typography} item>
        <Typography variant={"body2"}>{text}</Typography>
      </Grid>
    </Grid>
  );
};

export default IphoneIconWithText;
