import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((props) => ({
  icon: {
    borderRadius: (props) => (props.radius ? props.radius : "10px"),
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));",
  },
  iconWithLink: {
    borderRadius: (props) => (props.radius ? props.radius : "10px"),
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));",
    "&:hover": {
      cursor: "pointer",
      opacity: 0.5,
    },
  },
}));

const IphoneIcon = ({ src, size, link, ...props }) => {
  const classes = useStyles(props);
  const handleClick = () => {
    window.open(link);
  };
  return link ? (
    <img className={classes.iconWithLink} alt="icon" src={src} width={size} height={size} onClick={handleClick} />
  ) : (
    <img className={classes.icon} alt="icon" src={src} width={size} height={size} />
  );
};

export default IphoneIcon;
