import React from "react";
import { Grid, Typography, makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

//todo: iphone/responsive layout
const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.up("md")]: {
    container: {
      marginTop: theme.spacing(9),
      marginLeft: theme.spacing(12),
    },
    logo: {},
    introduction: {
      marginTop: theme.spacing(11),
    },
    description: {
      marginTop: theme.spacing(5),
    },
    switch: { marginTop: theme.spacing(19) },
  },
  [theme.breakpoints.down("sm")]: {
    container: {
      marginTop: theme.spacing(8),
    },
    introduction: {
      marginTop: theme.spacing(11),
    },
    description: {
      marginTop: theme.spacing(7),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    switch: { marginTop: theme.spacing(19) },
  },
}));

const LandingPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid
      className={classes.container}
      justify={mobile ? "center" : "flex-start"}
      alignItems={mobile ? "center" : "flex-start"}
      container
      direction={"column"}
    >
      <Grid item className={classes.logo}>
        <img src={"/Logo.png"} className="App-logo" alt="logo" />
      </Grid>
      <Grid item className={classes.introduction}>
        <Typography variant={"h2"}>I'm Jack</Typography>
      </Grid>
      <Grid item xs={mobile ? 12 : 6} className={classes.description}>
        <Typography align={mobile ? "center" : "inherit"} variant={"body1"}>
          A Software Engineer based in London. I work with tech companies and entrepreneurs all over the world.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LandingPage;
