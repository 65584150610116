import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { PortfolioPiece } from "../../components";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.up("md")]: {
    portfolioPiece: {
      marginTop: theme.spacing(10),
    },
    container: {
      marginTop: theme.spacing(9),
      display: (props) => (props.hidden ? "none" : ""),
    },
    breaker: {
      width: "100%",
    },
  },
  [theme.breakpoints.down("sm")]: {
    portfolioPiece: {
      marginTop: theme.spacing(6),
    },
    container: {
      marginTop: theme.spacing(9),
      display: (props) => (props.hidden ? "none" : ""),
    },
    test: {
      width: "100%",
    },
  },
}));

const Portfolio = ({ ...props }) => {
  const classes = useStyles(props);
  return (
    <Grid className={classes.container} container justify={"center"}>
      <Grid item className={classes.portfolioPiece}>
        <PortfolioPiece
          title={"EyeAm."}
          caption={
            "Built a fully custom Shopify store and gallery for a prominent NFT artist using React and the Shopify Storefront API."
          }
          link={"https://www.eyeam.ai"}
          imageSrc={"./eyeAm.png"}
          color={"#feffed"}
        />
      </Grid>
      <Grid item className={classes.breaker}>
        <div />
      </Grid>

      <Grid item className={classes.portfolioPiece}>
        <PortfolioPiece
          title={"Draft Mockups"}
          caption={"Created Figma mockups for a mobile banking application for freelancers called Draft."}
          imageSrc={"./draftBankingLandingPage.png"}
          link={
            "https://www.figma.com/proto/3BxdQh3KmeFw9i9l4iRIdg/Landing-Page?node-id=1%3A50&scaling=min-zoom&page-id=0%3A1"
          }
          color={"#f4f6f8"}
        />
      </Grid>
      <Grid item className={classes.breaker}>
        <div />
      </Grid>
      <Grid item className={classes.portfolioPiece}>
        <PortfolioPiece
          title={"Marketing Dash"}
          caption={
            "Created a marketing analytics dashboard for a digital agency’s clients to get a high level view of their Facebook, Google and LinkedIn Data"
          }
          imageSrc={"./marketingDashboardPortfolio.png"}
          color={"#edfbff"}
        />
      </Grid>
      <Grid item className={classes.breaker}>
        <div />
      </Grid>
      <Grid className={classes.portfolioPiece} item>
        <PortfolioPiece
          title={"Comaiko"}
          caption={"Comaiko is an application for construction businesses to subcontract employees to one another"}
          link={"https://www.comaiko.com"}
          imageSrc={"./comaikoPortfolio.png"}
          color={"#FFE9E9"}
        />
      </Grid>
    </Grid>
  );
};

export default Portfolio;
