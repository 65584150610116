import React from "react";
import { SkillsSection } from "../../components";
import { makeStyles, useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.up("md")]: {
    container: {
      marginLeft: theme.spacing(12),
      display: (props) => (props.hidden ? "none" : ""),
    },
  },
  [theme.breakpoints.down("sm")]: {
    container: {
      display: (props) => (props.hidden ? "none" : ""),
    },
  },
}));

const webDesignObjects = [
  { text: "Figma Wireframing", src: "./figmaLogo.png" },
  { text: "React Development", src: "./reactLogo.png" },
  { text: "Shopify Custom Themes", src: "./shopifyLogo.png" },
];

const cloudDevelopmentObjects = [
  { text: "Amazon Web Services", src: "./awsLogo.png" },
  { text: "Stripe Integrations", src: "./stripeLogo.png" },
  { text: "Shopify App Development", src: "./shopifyLogo.png" },
  { text: "Google Analytics", src: "./googleAnalyticsLogo.png" },
  { text: "Retool Applications", src: "./retoolLogo.png" },
];

const internalApplicationObjects = [
  { text: "Python Development", src: "./pythonLogo.png" },
  { text: "Java Development", src: "./javaLogo.png" },
  { text: "JavaScript Development", src: "./javascriptLogo.png" },
];

const Skills = ({ ...props }) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid
      justify={mobile ? "center" : "flex-start"}
      alignItems={mobile ? "center" : "flex-start"}
      className={classes.container}
      container
      direction={"column"}
    >
      <Grid item>
        <SkillsSection skillsSectionHeader={"Website Design"} skillsAndIconsSrcObjects={webDesignObjects} />
      </Grid>
      <Grid item>
        <SkillsSection skillsSectionHeader={"Cloud Development"} skillsAndIconsSrcObjects={cloudDevelopmentObjects} />
      </Grid>
      <Grid item>
        <SkillsSection
          skillsSectionHeader={"Internal Applications"}
          skillsAndIconsSrcObjects={internalApplicationObjects}
        />
      </Grid>
    </Grid>
  );
};

export default Skills;
