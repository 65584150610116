import React, { useState } from "react";
import { ButtonGroup, Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  buttonOn: {
    background: "#000000",
    color: "#FFFFFF",
    "&:hover": {
      background: "#343434",
    },
    fontSize: "16px",
    padding: "5px 20px",
  },
  buttonOff: {
    fontSize: "16px",
    padding: "5px 20px",
  },
}));

const Switch = ({ handleSwitch }) => {
  const classes = useStyles();
  const [firstButton, setFirstButton] = useState(true);
  const [secondButton, setSecondButton] = useState(false);

  const handleFirstButtonClick = () => {
    setSecondButton(false);
    setFirstButton(true);
    handleSwitch("portfolio");
  };

  const handleSecondButtonClick = () => {
    setFirstButton(false);
    setSecondButton(true);
    handleSwitch("services");
  };

  return (
    <ButtonGroup disableElevation aria-label="outlined primary button group">
      <Button onClick={handleFirstButtonClick} className={firstButton ? classes.buttonOn : classes.buttonOff}>
        Portfolio
      </Button>
      <Button onClick={handleSecondButtonClick} className={secondButton ? classes.buttonOn : classes.buttonOff}>
        Services
      </Button>
    </ButtonGroup>
  );
};

export default Switch;
