//todo
const font = "'Archivo', sans-serif";
const typograhpy = {
  h1: {
    fontWeight: 700,
    fontSize: 72,
  },
  h2: {
    fontWeight: 700,
    fontSize: 48,
  },
  h3: {
    fontWeight: 700,
    fontSize: 30,
  },
  h4: {
    fontWeight: 700,
    fontSize: 38,
  },
  body1: {
    fontWeight: 700,
    fontSize: 24,
  },
  body2: {
    fontWeight: 600,
    fontSize: 20,
  },
  subtitle1: {
    fontWeight: 600,
    fontSize: 18,
  },
  caption: {
    fontWeight: 400,
    fontSize: 16,
  },

  fontFamily: font,
};

export default typograhpy;
