import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { AccordionItem } from "../../components/AccordionItem";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
  },
  accordionItem: {
    marginTop: theme.spacing(5),
  },
  [theme.breakpoints.up("md")]: {
    marginTop: theme.spacing(10),
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(10),
  },
  [theme.breakpoints.down("sm")]: {},
}));

const Reviews = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className={classes.root}>
      <Grid alignItems={mobile ? "center" : "flex-start"} justify={"center"} container>
        <Grid item>
          <Typography align={"center"} variant={"h4"}>
            Previously Partnered With
          </Typography>
        </Grid>
      </Grid>
      <Grid item className={classes.accordionItem}>
        <AccordionItem
          logoSrc={"./codeNl.png"}
          summary={`Code.nl is the largest Shopify Plus agency in the Netherlands. They reached out to me to create additional functionality on an 
          integration between Shopify and a Dutch warehouse management system called Picqer.
          I created a Custom Shopify App connected to AWS in order to properly keep stock counts up to date after POS sales`}
          smallBlackLogoSrcs={["awsSmallBlackLogo.png", "shopifySmallBlackLogo.png", "javascriptSmallBlackLogo.png"]}
          width={mobile ? 80 : 120}
          mobile={mobile}
        />
      </Grid>
      <Grid item className={classes.accordionItem}>
        <AccordionItem
          logoSrc={"./nicheMasteryLogo.png"}
          summary={`Niche Mastery is a digital art store. Their Etsy store required manually uploading many images and options when creating listings which took a significant amount of time that they wanted to be spending working on their 
          craft. I built a custom solution with the Etsy API which configured their product listings for them so they could spend more time doing what they love`}
          smallBlackLogoSrcs={["javascriptSmallBlackLogo.png"]}
          width={mobile ? 100 : 150}
          mobile={mobile}
        />
      </Grid>
      <Grid item className={classes.accordionItem}>
        <AccordionItem
          logoSrc={"./gandcoLogo.png"}
          summary={`Genevieve & Co is a high end sustainable fashion ecommerce aggregator. They reached out to me with a problem that they needed to keep up to date inventory items from their suppliers. I built them a custom Shopify Private app to integrate with their suppliers using AWS Lambda and Python.`}
          smallBlackLogoSrcs={["awsSmallBlackLogo.png", "shopifySmallBlackLogo.png", "pythonSmallBlackLogo.png"]}
          width={mobile ? 120 : 180}
          mobile={mobile}
        />
      </Grid>
    </div>
  );
};

export default Reviews;
